import { toast } from 'react-toastify';
import { getFirebaseEntry, updateFirebaseEntry } from './firebaseProxy';

const collection = 'instructionProviders';

/**
 *
 * @param {string} id
 * @returns
 */
export const fetchInstructionProvider = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection, id }).then((response) => {
      const instructionProvider = response || null;

      resolve(instructionProvider);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 *
 * @param {object} data // data to save
 * @param {string} id // "docId"
 */
export const updateInstructionProvider = (data, id) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id
    }).then(() => {
      resolve();
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`update ${collection}`, error);
    });
  });
};
