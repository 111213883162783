import { PATHWAYS_PIXELATED_MAP_LIGHT } from '../constants/assets';

const renderElement = ({
  ElementName,
  ElementId,
  ElementDescription,
  Importance
}) => {
  return `
    <p style="margin: 0; font-size: 14px;">
      <strong><span style="font-size:15px;">${ElementName}</span> </strong>(${Importance})
    </p>
    <p style="margin: 0; font-size: 14px;">
      <span style="font-size:14px;">
        ${ElementDescription}
      </span>
    </p>
    <p style="margin: 0; font-size: 14px; line-height: 2.5;">
      <strong>ID:</strong> ${ElementId}
    </p>
    <p style="margin: 0; font-size: 14px; mso-line-height-alt: 16.8px;">
      <br/>
    </p>
  `;
};

const renderElements = (selectedElements, title) => {
  const elements = selectedElements.map((element) => {
    return renderElement(element);
  });

  return `
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
              <tbody>
                <tr>
                  <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 0px solid #000000; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 1px dashed #BBBBBB; padding-left: 5px; padding-right: 5px;" width="100%">
                    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                      <tr>
                        <td style="width:100%;text-align:center;padding-left:10px;padding-top:20px;">
                          <h2 style="margin: 0; color: #100e0e; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 200%; text-align: left; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
                            <strong>${title}</strong>
                          </h2>
                        </td>
                      </tr>
                    </table>

                    <table border="0" cellpadding="10" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                      <tr>
                        <td>
                          <div align="center">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                              <tr>
                                <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px dashed #DCDCDC;"><span></span></td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
                      <tr>
                        <td style="padding-top:10px;padding-right:10px;padding-bottom:20px;padding-left:10px;">
                          <div style="font-family: sans-serif">
                            <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                              ${elements.join('')}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `;
};

const renderCert = ({
  Name,
  Id,
  Type
}) => {
  return `
    <p style="margin: 0; font-size: 14px;">
      <strong><span style="font-size:15px;">${Name}</span> </strong>
    </p>
    <p style="margin: 0; font-size: 14px; line-height: 2.5;">
      <strong>Type:</strong> ${Type}, <strong>ID:</strong> ${Id}
    </p>
    <p style="margin: 0; font-size: 14px; mso-line-height-alt: 16.8px;">
      <br/>
    </p>
  `;
};

const renderCerts = (selectedCerts, title = 'Certifications') => {
  if (!selectedCerts) return '';

  if (selectedCerts && Array.isArray(selectedCerts) && selectedCerts.length === 0) {
    return '';
  }

  const certs = selectedCerts.map((cert) => {
    return renderCert(cert);
  });

  return `
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
              <tbody>
                <tr>
                  <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 0px solid #000000; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 1px dashed #BBBBBB; padding-left: 5px; padding-right: 5px;" width="100%">
                    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                      <tr>
                        <td style="width:100%;text-align:center;padding-left:10px;padding-top:20px;">
                          <h2 style="margin: 0; color: #100e0e; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 200%; text-align: left; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
                            <strong>${title}</strong>
                          </h2>
                        </td>
                      </tr>
                    </table>

                    <table border="0" cellpadding="10" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                      <tr>
                        <td>
                          <div align="center">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                              <tr>
                                <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px dashed #DCDCDC;"><span></span></td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
                      <tr>
                        <td style="padding-top:10px;padding-right:10px;padding-bottom:20px;padding-left:10px;">
                          <div style="font-family: sans-serif">
                            <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                              ${certs.join('')}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `;
};

const renderSchoolProgram = ({
  ProgramName,
  ID,
  SchoolName,
  StateAbbr
}) => {
  return `
    <p style="margin: 0; font-size: 14px;">
      <strong><span style="font-size:15px;">${ProgramName}</span> </strong>
    </p>
    <p style="margin: 0; font-size: 14px;">
      <span style="font-size:14px;">
        ${SchoolName}
      </span>
    </p>
    <p style="margin: 0; font-size: 14px; line-height: 2;">
      <strong>State:</strong> ${StateAbbr}, <strong>ID:</strong> ${ID}
    </p>
    <p style="margin: 0; font-size: 14px; mso-line-height-alt: 16.8px;">
      <br/>
    </p>
  `;
};

const renderSchoolPrograms = (selectedSchoolPrograms, title = 'Training Programs') => {
  if (!selectedSchoolPrograms) return '';

  if (selectedSchoolPrograms
    && Array.isArray(selectedSchoolPrograms)
    && selectedSchoolPrograms.length === 0
  ) {
    return '';
  }

  const schoolPrograms = selectedSchoolPrograms.map((schoolProgram) => {
    return renderSchoolProgram(schoolProgram);
  });

  return `
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
              <tbody>
                <tr>
                  <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 0px solid #000000; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 1px dashed #BBBBBB; padding-left: 5px; padding-right: 5px;" width="100%">
                    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                      <tr>
                        <td style="width:100%;text-align:center;padding-left:10px;padding-top:20px;">
                          <h2 style="margin: 0; color: #100e0e; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 200%; text-align: left; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
                            <strong>${title}</strong>
                          </h2>
                        </td>
                      </tr>
                    </table>

                    <table border="0" cellpadding="10" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                      <tr>
                        <td>
                          <div align="center">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
                              <tr>
                                <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px dashed #DCDCDC;"><span></span></td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
                      <tr>
                        <td style="padding-top:10px;padding-right:10px;padding-bottom:20px;padding-left:10px;">
                          <div style="font-family: sans-serif">
                            <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                              ${schoolPrograms.join('')}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `;
};

const pathwayEmail = ({
  id,
  OnetTitle,
  OnetCode,
  OccupationDescription,
  location = '',
  occupationLocation = '',
  selectedSkills,
  selectedKnowledge,
  selectedCerts,
  selectedSchoolPrograms
}) => {
  return `
    <!DOCTYPE html>

    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
    <head>
    <title></title>
    <meta charset="utf-8"/>
    <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <style>
        * {
          box-sizing: border-box;
        }

        th.column {
          padding: 0
        }

        a[x-apple-data-detectors] {
          color: inherit !important;
          text-decoration: inherit !important;
        }

        #MessageViewBody a {
          color: inherit;
          text-decoration: none;
        }

        p {
          line-height: inherit
        }

        @media (max-width:520px) {
          .icons-inner {
            text-align: center;
          }

          .icons-inner td {
            margin: 0 auto;
          }

          .fullMobileWidth,
          .row-content {
            width: 100% !important;
          }

          .image_block img.big {
            width: auto !important;
          }

          .stack .column {
            width: 100%;
            display: block;
          }
        }
      </style>
    </head>
    <body style="margin: 0; background-color: #000; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #000000; background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png'); background-repeat: repeat;" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top;" width="100%">
    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;padding-right:0px;padding-left:0px;padding-top:25px;padding-bottom:25px;">
    <div align="center" style="line-height:10px"><a href="https://turbineworkforce.com?track=eplanLogoW" style="outline:none" tabindex="-1" target="_blank"><img src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo-white.png" style="display: block; height: auto; border: 0; width: 150px; max-width: 100%;" width="150"/></a></div>
    </td>
    </tr>
    </table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-top: 1px dashed #000000; padding-left: 10px;" width="100%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;text-align:center;padding-left:10px;padding-top:20px;">
    <h2 style="margin: 0; color: #555555; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: left; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;"><strong>Training</strong><strong> Plan</strong></h2>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="padding-top:15px;padding-right:10px;padding-left:10px;">
    <div align="center">
    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #BBBBBB;"><span></span></td>
    </tr>
    </table>
    </div>
    </td>
    </tr>
    </table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #BBBBBB; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 0px solid #000000; padding-left: 10px; padding-right: 10px;" width="66.66666666666667%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;text-align:center;padding-left:10px;padding-top:15px;">
      <h2 style="margin: 0; color: #000000; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: left; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
        <strong>${OnetTitle}</strong>
      </h2>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tr>
    <td style="padding-top:10px;padding-right:10px;padding-bottom:20px;padding-left:10px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px;">${OccupationDescription}</p>
    <p style="margin: 0; font-size: 14px; line-height: 2;"><strong>Onet Code:</strong> ${OnetCode}</p>
    </div>
    </div>
    </td>
    </tr>
    </table>
    </th>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #BBBBBB; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 0px solid #000000; padding-left: 10px; padding-right: 10px;" width="33.333333333333336%">
    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;padding-right:0px;padding-left:0px;padding-top:15px;">
    <div align="center" style="line-height:10px"><img class="fullMobileWidth big" src="${PATHWAYS_PIXELATED_MAP_LIGHT}" style="display: block; height: auto; border: 0; width: 147px; max-width: 100%;" width="147"/></div>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tr>
    <td style="padding-top:10px;padding-right:5px;padding-bottom:15px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px;">📍${occupationLocation} (${location})</p>
    </div>
    </div>
    </td>
    </tr>
    </table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #060606; background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png'); background-repeat: repeat;" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #BBBBBB; border-left: 1px dashed #BBBBBB; border-right: 1px dashed #BBBBBB; border-top: 1px dashed #BBBBBB;" width="25%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;text-align:center;padding-top:10px;">
    <h1 style="margin: 0; color: #000000; font-size: 23px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
      <strong>
        ${selectedSkills.length}
      </strong>
    </h1>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tr>
    <td style="padding-top:10px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px; text-align: center;">Skills</p>
    </div>
    </div>
    </td>
    </tr>
    </table>
    </th>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #BBBBBB; border-left: 1px dashed #BBBBBB; border-right: 1px dashed #BBBBBB; border-top: 1px dashed #BBBBBB;" width="25%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;text-align:center;padding-top:10px;">
    <h1 style="margin: 0; color: #000000; font-size: 23px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
      <strong>
        ${selectedKnowledge.length}
      </strong>
    </h1>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tr>
    <td style="padding-top:10px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px; text-align: center;">Knowledge</p>
    </div>
    </div>
    </td>
    </tr>
    </table>
    </th>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #BBBBBB; border-left: 1px dashed #BBBBBB; border-right: 1px dashed #BBBBBB; border-top: 1px dashed #BBBBBB;" width="25%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;text-align:center;padding-top:10px;">
    <h1 style="margin: 0; color: #000000; font-size: 23px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
      <strong>
        ${selectedSchoolPrograms && Array.isArray(selectedSchoolPrograms) && selectedSchoolPrograms.length !== 0 ? selectedSchoolPrograms.length : '0'}
      </strong>
    </h1>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tr>
    <td style="padding-top:10px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px; text-align: center;">Programs</p>
    </div>
    </div>
    </td>
    </tr>
    </table>
    </th>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #BBBBBB; border-left: 1px dashed #BBBBBB; border-right: 1px dashed #BBBBBB; border-top: 1px dashed #BBBBBB;" width="25%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tr>
    <td style="width:100%;text-align:center;padding-top:10px;">
    <h1 style="margin: 0; color: #000000; font-size: 23px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
      <strong>
        ${selectedCerts && Array.isArray(selectedCerts) && selectedCerts.length !== 0 ? selectedCerts.length : '0'}
      </strong>
    </h1>
    </td>
    </tr>
    </table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tr>
    <td style="padding-top:10px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px; text-align: center;">Certifications</p>
    </div>
    </div>
    </td>
    </tr>
    </table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>

    ${renderElements(selectedSkills, 'Skills')}

    ${renderElements(selectedKnowledge, 'Knowledge')}

    ${renderCerts(selectedCerts)}

    ${renderSchoolPrograms(selectedSchoolPrograms)}

    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-7" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #100e0e; background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png'); background-repeat: repeat;" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #000000;" width="100%">
    <table border="0" cellpadding="0" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tbody><tr>
    <td style="padding-top:25px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
    <div align="center">
    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tbody><tr>
    <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px dashed #DCDCDC;"><span></span></td>
    </tr>
    </tbody></table>
    </div>
    </td>
    </tr>
    </tbody></table>
    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tbody><tr>
    <td style="padding-top:10px;padding-right:10px;padding-bottom:25px;padding-left:10px;">
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
    <p style="margin: 0; font-size: 14px; text-align: center;">Powered by <a href="https://turbineworkforce.com?track=eplanPowered" rel="noopener" style="text-decoration: underline; color: #8e44ad;" target="_blank">Turbine Workforce</a></p>
    </div>
    </div>
    </td>
    </tr>
    </tbody></table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>


    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-8" role="presentation" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top;" width="100%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tbody><tr>
    <td style="width:100%;text-align:center;padding-top:35px;padding-bottom:35px;">
    <h1 style="margin: 0; color: #ffffff; font-size: 24px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">
      <strong>Put this plan into action!</strong>
    </h1>
    </td>
    </tr>
    </tbody></table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>


    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-9" role="presentation" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #000000; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 1px dashed #000000; padding-left: 10px; padding-right: 10px;" width="100%">
    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tbody><tr>
    <td style="width:100%;text-align:center;padding-top:20px;padding-bottom:10px;">
    <h2 style="margin: 0; color: #555555; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;"><strong>Create a training pathway from this plan</strong></h2>
    </td>
    </tr>
    </tbody></table>
    <table border="0" cellpadding="10" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;" width="100%">
    <tbody><tr>
    <td>
    <div style="font-family: sans-serif">
    <div style="font-size: 14px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; color: #555555; line-height: 1.2;">
    <p style="margin: 0; font-size: 15px;"><span style="font-size:16px;"><strong>Employers:</strong></span></p>
    <ul style="font-size: 15px;">
    <li><span style="font-size:15px;">We will&nbsp;<strong>crowdsource</strong>&nbsp;regional &amp; online trainings,&nbsp;<strong>negotiate</strong> the lowest cost.</span></li>
    <li>Optimize state &amp; fed&nbsp;<strong style="font-family:inherit;font-family:inherit;background-color:transparent;">reimbursements, tax credits</strong><span style="background-color:transparent;">.</span></li>
    <li>Automate&nbsp;<strong style="font-family:inherit;font-family:inherit;background-color:transparent;">Apprenticeship</strong><span style="background-color:transparent;">&nbsp;Job Books.</span></li>
    </ul>
    <p style="margin: 0; font-size: 15px;"><span style="font-size:16px;"><strong>Professional Development:</strong></span></p>
    <ul style="font-size: 15px;">
    <li><span style="font-size:15px;">Optimize PD offerings to meet employer demand.</span></li>
    <li>Embed this tool on website and create training pathway of your PD programs for job seekers.</li>
    </ul>
    <p style="margin: 0; font-size: 15px;"><span style="font-size:16px;"><strong>Workforce Pros &amp; Local Workforce Development Boards:</strong></span></p>
    <ul style="font-size: 15px;">
    <li><span style="font-size:15px;">Embed this tool on LWDB website.</span></li>
    <li>Engage &amp; Boost employer&nbsp;<strong style="font-family:inherit;font-family:inherit;background-color:transparent;">engagement &amp; renewal rates</strong><span style="background-color:transparent;">.</span></li>
    </ul>
    </div>
    </div>
    </td>
    </tr>
    </tbody></table>
    <table border="0" cellpadding="0" cellspacing="0" class="button_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tbody><tr>
    <td style="text-align:center;padding-top:10px;padding-right:10px;padding-bottom:30px;padding-left:10px;">
    <div align="center">
    <!--[if mso]><a:roundrect xmlns:a="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://turbineworkforce.com/contact-us?track=eplanBtn" style="height:42px;width:121px;v-text-anchor:middle;" arcsize="10%" stroke="false" fillcolor="#8e44ad"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff!important; font-family:Arial, sans-serif; font-size:16px"><![endif]-->
    <a href="https://turbineworkforce.com/contact-us?track=eplanBtn" style="text-decoration:none;display:inline-block;color:#ffffff!important;background-color:#8e44ad;border-radius:4px;width:auto;border-top:1px solid #8e44ad;border-right:1px solid #8e44ad;border-bottom:1px solid #8e44ad;border-left:1px solid #8e44ad;padding-top:5px;padding-bottom:5px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all;" target="_blank">
      <span style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:normal;">
        <span style="font-size: 16px; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;">
          Get Started
        </span>
      </span>
    </a>
    <!--[if mso]></center></v:textbox></a:roundrect><![endif]-->
    </div>

    <div align="center" style="padding-top:20px;">
      <p style="margin: 0; font-size: 14px;">
        <strong><span style="font-size:15px;">Plan ID:</span> </strong>${id}
      </p>
    </div>

    </td>
    </tr>
    </tbody></table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>


    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-10" role="presentation" width="100%">
    <tbody>
    <tr>
    <td>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="500">
    <tbody>
    <tr>
    <th class="column" style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top;" width="100%">
    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" style="mso-table-lspace: 0; mso-table-rspace: 0;" width="100%">
    <tbody><tr>
    <td style="width:100%;padding-right:0px;padding-left:0px;padding-top:30px;padding-bottom:50px;">
    <div align="center" style="line-height:10px">
      <a href="https://turbineworkforce.com?track=eplanLogoW" style="outline:none" tabindex="-1" target="_blank">
        <img src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo-white.png" style="display: block; height: auto; border: 0; width: 125px; max-width: 100%;" width="125" />
      </a>
    </div>
    </td>
    </tr>
    </tbody></table>
    </th>
    </tr>
    </tbody>
    </table>
    </td>
    </tr>
    </tbody>
    </table>



    </td>
    </tr>
    </tbody>
    </table><!-- End -->
    </body>
    </html>
  `;
};

export default pathwayEmail;
